var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[(!_vm.checkEmailTemplateExists())?_c('div',[_vm._v("Please Select Template")]):_c('div',[(this.currentEntity.viewType != 'HTML_CONTENT')?_c('div',{staticClass:"size-selector"},[_c('label',{attrs:{"for":"cardHeight"}},[_vm._v("Card Height:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cardHeight),expression:"cardHeight"}],attrs:{"type":"range","id":"cardHeight","min":"100","max":"700"},domProps:{"value":(_vm.cardHeight)},on:{"__r":function($event){_vm.cardHeight=$event.target.value}}}),_c('span',[_vm._v(_vm._s(_vm.cardHeight)+"px")])]):_vm._e(),_c('el-row',[_c('el-col',{attrs:{"md":20,"sm":24,"xs":24}},[_c('div',{staticClass:"filter-card",staticStyle:{"overflow-y":"hidden"}},[_c('div',{staticStyle:{"margin-left":"60px"}},[_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Previous page","placement":"top-end"}},[_c('el-link',{attrs:{"icon":"el-icon-arrow-left","disabled":_vm.currentPage == 1,"underline":false,"type":"primary"},on:{"click":function($event){return _vm.applyPagination('previous')}}})],1),_c('el-tooltip',{staticClass:"item",attrs:{"effect":"dark","content":"Next page","placement":"top-end"}},[_c('el-link',{attrs:{"icon":"el-icon-arrow-right","disabled":_vm.currentPage * _vm.pageSize >=this.total,"underline":false,"type":"primary"},on:{"click":function($event){return _vm.applyPagination('next')}}})],1)],1),_c('br')])])],1),_c('el-row',{staticStyle:{"display":"flex","flex-direction":"row"}},[_c('el-col',{staticStyle:{"overflow-y":"hidden"},attrs:{"span":4}},[_c('el-scrollbar',{attrs:{"wrap-style":"min-height: 70vh"}},[_c('div',{staticClass:"card-summary"},_vm._l((_vm.data),function(template,index){return _c('div',{key:index,staticClass:"summary",on:{"click":function($event){return _vm.selectedCard(template)}}},[(
                  template.entity_prime_data &&
                  template.entity_prime_data?.title
                )?_c('div',[_c('span',[_vm._v("Title:")]),_vm._v(_vm._s(template.entity_prime_data?.title)+" ")]):(
                  template.entity_prime_data &&
                  template.entity_prime_data?.name
                )?_c('div',[_c('span',[_vm._v("Name:")]),_vm._v(_vm._s(template.entity_prime_data?.name)+" ")]):_c('div',[(
                    _vm.getFieldKey('title') && _vm.getFieldValue('title', template)
                  )?_c('div',[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("title"))+" ")]),_vm._v(": "),_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", template),140, "...")))])]):_vm._e(),(
                    _vm.getFieldKey('description') &&
                    _vm.getFieldValue('description', template)
                  )?_c('div',[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("description"))+" ")]),_vm._v(": "),_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("description", template),140, "...")))])]):_vm._e()])])}),0)])],1),_c('el-col',{staticStyle:{"max-height":"60vh"},attrs:{"span":6}},[_c('div',{staticStyle:{"overflow-y":"hidden"}},[_c('el-scrollbar',{attrs:{"wrap-style":"max-height: calc(100vh - 180px)"}},[(_vm.middleDivFilterData.length )?_c('div',{staticClass:"cards"},_vm._l((_vm.middleDivFilterData),function(row,index){return _c('div',{key:index,staticStyle:{"overflow-x":"hidden"}},[(
                    _vm.getProfilePicture(row) ||
                    _vm.getFieldValue('profile', row) ||
                    _vm.getFieldValue('title', row) ||
                    _vm.getFieldValue('description', row) ||
                    _vm.getFieldValue('field1', row) ||
                    _vm.getFieldValue('field2', row) ||
                    _vm.getFieldValue('field3', row)
                  )?_c('div',{staticClass:"entity-data-card",on:{"dblclick":function($event){return _vm.cellDoubleClicked(row)}}},[(
                      _vm.isEditing.title ||
                      _vm.isEditing.description ||
                      _vm.isEditing.field1 ||
                      _vm.isEditing.field2 ||
                      _vm.isEditing.field3
                    )?_c('span',{staticClass:"edit-icons"},[_c('i',{staticClass:"tick-icon",on:{"click":function($event){return _vm.doubleClickUpdate(row)}}},[_vm._v("✔️")]),_c('i',{staticClass:"cross-icon",on:{"click":_vm.cancelEditAll}},[_vm._v("❌")])]):_vm._e(),_c('div',{staticClass:"card-profile"},[(_vm.getProfilePicture(row))?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.getProfilePicture(row)}}):_vm._e(),(_vm.getFieldValue('profile', row))?_c('imgawai',{staticStyle:{"border-radius":"50%"},attrs:{"src":_vm.getFieldValue('profile', row),"alt":"icon"}}):_c('div',[(_vm.currentEntity.entity_type == 'INDIVIDUAL')?_c('img',{staticStyle:{"border-radius":"50%"},attrs:{"src":require("@/assets/img/defaultAvatar/Individual.svg"),"alt":"icon"}}):_c('img',{attrs:{"src":require("@/assets/img/defaultAvatar/Business.svg"),"alt":"icon"}})])],1),_c('div',{staticClass:"card-body"},[(
                        _vm.getFieldKey('title') && _vm.getFieldValue('title', row)
                      )?_c('p',{staticClass:"card-title"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("title"))+" ")]),_vm._v(": "),(!_vm.isEditing.title)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("title", row),140, "...")))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.title),expression:"editableFields.title"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.title)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "title", $event.target.value)}}})]):_vm._e(),(
                        _vm.getFieldKey('description') &&
                        _vm.getFieldValue('description', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("description"))+" ")]),_vm._v(": "),(!_vm.isEditing.description)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("description", row),140, "...")))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.description),expression:"editableFields.description"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "description", $event.target.value)}}})]):_vm._e(),(
                        _vm.getFieldKey('field1') && _vm.getFieldValue('field1', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("field1"))+" ")]),_vm._v(": "),(!_vm.isEditing.field1)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("field1", row),140, "...")))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.field1),expression:"editableFields.field1"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.field1)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "field1", $event.target.value)}}})]):_vm._e(),(
                        _vm.getFieldKey('field2') && _vm.getFieldValue('field2', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("field2"))+" ")]),_vm._v(": "),(!_vm.isEditing.field2)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("field2", row),140, "...")))]):_vm._e()]):_vm._e(),(
                        _vm.getFieldKey('field3') && _vm.getFieldValue('field3', row)
                      )?_c('p',{staticClass:"card-description header"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldKey("field3"))+" ")]),_vm._v(": "),(!_vm.isEditing.field1)?_c('span',{staticClass:"card-data"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue("field3", row),140, "...")))]):_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.editableFields.field3),expression:"editableFields.field3"}],staticClass:"edit-input",domProps:{"value":(_vm.editableFields.field3)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.editableFields, "field3", $event.target.value)}}})]):_vm._e(),_c('br'),(
                        _vm.showFields.action_group_button &&
                        _vm.showFields.action_group_button.length
                      )?_vm._l((_vm.showFields.action_group_button),function(action,index){return _c('div',{key:index},[(_vm.hasHash(action))?[_c('action-button-execute',{staticStyle:{"margin-top":"10px"},attrs:{"data":{
                              ..._vm.getActionField(action),
                              parent_entityDataId: row._id,
                            },"form":_vm.form,"is-view":false,"hideStyles":true}})]:[(
                              _vm.IframePermissions('VIEW', 'EntityGalleryView')
                            )?_c('a',{staticClass:"card-link",staticStyle:{"color":"rgb(64, 158, 255)","text-decoration":"none"},attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.viewWorkUserData(row, action)}}},[_vm._v(" "+_vm._s(action)+" ")]):_vm._e()]],2)}):_vm._e(),(
                        !_vm.showFields.action_group_button ||
                        !_vm.showFields.action_group_button.length
                      )?[(_vm.IframePermissions('VIEW', 'EntityGalleryView'))?_c('el-link',{staticClass:"card-link",staticStyle:{"margin-bottom":"10px"},attrs:{"type":"primary","underline":false},on:{"click":function($event){return _vm.viewWorkUserData(row)}}},[_vm._v(" View details ")]):_vm._e()]:_vm._e()],2)]):_c('el-empty',{staticClass:"entity-data-card",attrs:{"image-size":100,"description":"Not configured"}})],1)}),0):_c('div',[_c('el-empty',{attrs:{"description":"No data"}})],1)])],1)]),_c('el-col',{staticStyle:{"max-width":"60vw","max-height":"60vh","margin-right":"10px"},attrs:{"span":14}},[(_vm.selectedRecord)?_c('div',{staticClass:"card-container"},[_c('el-scrollbar',[_c('div',{staticClass:"card"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.selectedRecord.html)}})])])],1):_c('div',[_c('el-empty',{attrs:{"description":"No data"}})],1)])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }